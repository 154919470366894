import request from '@/utils/request'

export function getInviteCoach(page) {
    return request({
      method: 'GET',
      url: `/job-invite/get-invite-coach?page=${page}&count=10`,
    })
  }

  export function getUserInviteJob(page) {
    return request({
      method: 'GET',
      url: `/job-invite/get-invited-job?page=${page}&count=10`,
    })
  }
  export function getApplyJob(page,sortby,sortdirection) {
    return request({
      method: 'GET',
      url: `/job-apply/get-apply-job?page=${page}&count=10&sort=${sortby}&sort_direction=${sortdirection}`,
    })
  }
  export function getMyJob(page,sortby,sortdirection) {
    return request({
      method: 'GET',
      url: `/job-apply/get-my-job?page=${page}&count=10&sort=${sortby}&sort_direction=${sortdirection}`,
    })
  }
  export function putJobInvite(data) {
    return request({
      method: 'PUT',
      url: `/job-invite/${data.job_id}/${data.coach_id}`,
      data:{'message':data.message}
    })
  }
  export function putInviteConfirm(id,status) {
    return request({
      method: 'PUT',
      url: `/invite-confirm/${id}`,//invite_id
      data:{"status":status}
    })
  }
  export function putInstitutionsApply(id,status) {
    return request({
      method: 'PUT',
      url: `/job-apply/confirm/${id}`,//apply_id
      data:{"status":status}
    })
  }
  export function putUserApply(id,status) {
    return request({
      method: 'PUT',
      url: `/job-apply/user-confirm/${id}`,//apply_id
      data:{"status":status}
    })
  }
  export function copyJob(id) {
    return request({
      method: 'PUT',
      url: `/job-copy/${id}`,//job_id
    })
  }
  export function getRecord() {
    return request({
      method: 'GET',
      url: '/user-records'
    })
  }
  