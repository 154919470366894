<template>
  <div class="register-bg">
    <el-tabs class="account-information" tab-position="left" type="border-card" style="min-height: 800px">
      <el-tab-pane :label="$t('Register.my_Accounts')">
        <div class="title">{{ $t('Register.my_Accounts') }}</div>
        <el-form :label-width="labelWidth" :model="ruleForm1" ref="ruleForm1" v-loading="loading">
          <el-form-item required :label="$t('Register.name_en')">
            <el-input v-model="ruleForm1.name_en"></el-input>
          </el-form-item>
          <el-form-item required :label="$t('Register.name_cn')">
            <el-input v-model="ruleForm1.name_zh_tw"></el-input>
          </el-form-item>
          <el-form-item required :label="$t('Register.email_address')">
            <el-input :disabled="true" v-model="ruleForm1.email"></el-input>
          </el-form-item>
          <el-form-item required :label="$t('Register.address')">
            <el-input v-model="ruleForm1.address"></el-input>
          </el-form-item>
          <el-form-item required :label="$t('Register.mobile')" class="mobile-label">
            <el-input type="text" v-model="ruleForm2.mobile">
              <template slot="prepend"> +852 </template>
            </el-input>
          </el-form-item>
          <el-form-item required class="sex" :label="$t('Register.sex')">
            <div class="sex_group">
              <el-radio-group v-model="ruleForm1.gender">
                <el-radio label="m">{{ $t('Register.sex_m') }}</el-radio>
                <el-radio label="f">{{ $t('Register.sex_w') }}</el-radio>
                <span>
                  <el-radio label="o" class="other">
                    {{ $t('Register.sex_other') }}
                  </el-radio>
                </span>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item :label="$t('Register.bir')">
            <div class=".col">
              <el-date-picker
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :placeholder="$t('Register.prompt_data')"
                v-model="ruleForm1.birth_of_date"
                class="data-length"
              ></el-date-picker>
            </div>
          </el-form-item>
          <!-- prop="capacity" -->
          <el-form-item required class="identity" :label="$t('Register.my_identity')">
            <el-checkbox-group v-model="ruleForm1.capacity">
              <el-checkbox label="sports_coach" name="type">{{ $t('Register.identity_coach') }}</el-checkbox>
              <el-checkbox label="physical_education_teacher" name="type">{{ $t('Register.identity_teacher') }}</el-checkbox>
              <el-checkbox label="social_worker" name="type">{{ $t('Register.identity_social') }}</el-checkbox>
              <el-checkbox label="youth_worker" name="type">{{ $t('Register.identity_yang') }}</el-checkbox>
              <el-checkbox label="parent" name="type">
                {{ $t('Register.identity_parents') }}
              </el-checkbox>
              <el-checkbox label="student" name="type">
                {{ $t('Register.identity_stu') }}
              </el-checkbox>
              <el-checkbox label="other" name="type" class="identity">
                <span>{{ $t('Register.edu_other') }}</span>
                <el-input class="other" size="mini" v-model="ruleForm1.other_capacity" :placeholder="$t('Register.prompt')"></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-button @click="updateMemberInfo" type="primary">
            {{ $t('Register.storage') }}
          </el-button>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('MemberZone.stu_title')">
        <p class="stu-tit">
          {{ $t('MemberZone.stu_record') }}
        </p>
        <div class="stu-progress">
          <p class="stu-txt">{{ $t('MemberZone.inspiration') }}</p>
          <el-table :data="finishedCourses" empty-text="暫無數據" border :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
            <el-table-column prop="num" label="#" width="50"> </el-table-column>
            <el-table-column prop="stuName" :label="$t('MemberZone.stu_name')"> </el-table-column>
            <el-table-column width="150" :label="$t('MemberZone.state')">
              <template slot-scope="scope">
                {{ studyTypes[scope.row.state] }}
              </template>
            </el-table-column>
            <el-table-column prop="achieve" :label="$t('MemberZone.achievement')" width="150"> </el-table-column>
          </el-table>
          <div class="download-btn" v-show="isInspireCompleted">
            <el-button @click="certification_one" type="primary">{{ $t('MemberZone.cert') }}</el-button>
          </div>
          <div class="stu-done">
            <p class="stu-txt">
              {{ $t('MemberZone.safeguarding') }}
            </p>
            <el-table :data="progressingCourses" border empty-text="暫無數據" :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
              <el-table-column prop="num" label="#" width="50"> </el-table-column>
              <el-table-column prop="stuName" :label="$t('MemberZone.stu_name')"> </el-table-column>
              <el-table-column width="150" :label="$t('MemberZone.state')">
                <template slot-scope="scope">
                  {{ studyTypes[scope.row.state] }}
                </template>
              </el-table-column>
              <el-table-column prop="achieve" :label="$t('MemberZone.achievement')" width="150"> </el-table-column>
            </el-table>
            <div class="download-btn" v-show="isSafeguardingFinished">
              <el-button @click="certification" type="primary">{{ $t('MemberZone.cert') }}</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- voluntary records -->
      <el-tab-pane :label="$t('MemberZone.upload_voluntary')">
        <el-tabs>
          <el-tab-pane :label="$t('MemberZone.upload_vol')">
            <div class="volunteer_section">
              <h3 style="margin-bottom: 30px">{{ $t('MemberZone.volunteer_encourage_1') }}</h3>

              <h4>{{ $t('MemberZone.volunteer_encourage_2') }}</h4>
              <div class="volunteer_section_marker">
                <h5>{{ $t('MemberZone.volunteer_desc') }}</h5>
              </div>
              <ul>
                <li>{{ $t('MemberZone.volunteer_list_1') }}</li>
                <li>{{ $t('MemberZone.volunteer_list_2') }}</li>
                <li>{{ $t('MemberZone.volunteer_list_3') }}</li>
                <li>{{ $t('MemberZone.volunteer_list_4') }}</li>
                <li>{{ $t('MemberZone.volunteer_list_5') }}</li>
              </ul>
              <div class="volunteer_section_example">
                <div class="volunteer_section_example--item">
                  <h6 class="volunteer_example_header">{{ $t('MemberZone.volunteer_mark_a') }}</h6>
                  <p>{{ $t('MemberZone.volunteer_example_1') }}</p>
                </div>
                <div class="volunteer_section_example--item">
                  <h6 class="volunteer_example_header">{{ $t('MemberZone.volunteer_mark_b') }}</h6>
                  <p>{{ $t('MemberZone.volunteer_example_2') }}</p>
                </div>
                <div class="volunteer_section_example--item">
                  <h6 class="volunteer_example_header">{{ $t('MemberZone.volunteer_mark_c') }}</h6>
                  <p>{{ $t('MemberZone.volunteer_example_3') }}</p>
                </div>
                <div class="volunteer_section_example--item">
                  <h6 class="volunteer_example_header">{{ $t('MemberZone.volunteer_mark_d') }}</h6>
                  <p>{{ $t('MemberZone.volunteer_example_4') }}</p>
                </div>
              </div>
            </div>
            <p class="please_provide">
              {{ $t('MemberZone.please_provide') }}
            </p>
            <el-form :label-width="labelWidth" :model="ruleForm2" ref="ruleForm2" v-loading="loading">
              <el-form-item required :label="$t('MemberZone.event_name')">
                <el-input v-model="ruleForm2.event_name"></el-input>
              </el-form-item>
              <el-form-item required :label="$t('MemberZone.organisation')">
                <el-input v-model="ruleForm2.organisation"></el-input>
              </el-form-item>
              <el-form-item label-width="150px" required :label="$t('MemberZone.contact_person')">
                <el-input v-model="ruleForm2.name"></el-input>
              </el-form-item>
              <el-form-item label-width="150px" required :label="$t('MemberZone.organiser_conact')">
                <el-input v-model="ruleForm2.phone"></el-input>
              </el-form-item>

              <!-- <el-form-item
                        required
                        :label="$t('MemberZone.organisation')"
                    >
                        <el-input v-model="ruleForm2.organisation"></el-input>
                    </el-form-item> -->
              <el-form-item label-width="250px" required :label="$t('MemberZone.email')">
                <el-input v-model="ruleForm2.email"></el-input>
              </el-form-item>
              <el-form-item required :label="$t('MemberZone.date')">
                <div class="col">
                  <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :placeholder="$t('MemberZone.date')" v-model="ruleForm2.date" class="data-length"></el-date-picker>
                </div>
              </el-form-item>
              <el-form-item :label="$t('MemberZone.time_start')" required>
                <div class="col">
                  <el-time-picker v-model="ruleForm2.time_start" format="HH:mm" value-format="HH:mm"> </el-time-picker>
                </div>
              </el-form-item>
              <el-form-item :label="$t('MemberZone.time_end')" required>
                <div class="col">
                  <el-time-picker v-model="ruleForm2.time_end" format="HH:mm" value-format="HH:mm"> </el-time-picker>
                </div>
              </el-form-item>
              <el-form-item required :label="$t('MemberZone.location')">
                <el-input v-model="ruleForm2.location"></el-input>
              </el-form-item>
              <el-form-item label-width="190px" required :label="$t('MemberZone.beneficiaries')">
                <el-input v-model="ruleForm2.number_of_beneficiaries"></el-input>
              </el-form-item>
              <p class="please_provide">
                {{ $t('MemberZone.upload_photo') }}
                <span>
                  <el-dialog title="" :visible.sync="dialogFormVisible" :width="screenWidth > 480 ? '50%' : '98%'">
                    <el-form>
                      <el-form-item style="text-align: center">
                        <el-upload
                          ref="upload1"
                          action="#"
                          list-type="picture-card"
                          :limit="1"
                          :multiple="false"
                          :on-remove="handleRemove1"
                          :on-change="handleChange1"
                          :auto-upload="false"
                          :before-upload="beforeUpload1"
                          :http-request="uploadPhotos1"
                          :file-list="fileList1"
                        >
                          <i v-if="fileList1.length < 1" slot="default" class="el-icon-plus" />
                        </el-upload>
                      </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="dialogFormVisible = false">{{ $t('CreateProfiles.cancel') }}</el-button>
                      <el-button type="primary" :loading="BtnLoading" @click="uploadPic1()">{{ $t('CreateProfiles.save') }}</el-button>
                    </div>
                  </el-dialog>
                </span>
              </p>
              <el-button @click="upload()" type="primary">
                {{ $t('MemberZone.uplpoad') }}
              </el-button>
              <p class="please_provide">
                {{ $t('MemberZone.make_sure') }}
              </p>

              <el-button @click="submit()" type="primary">
                {{ $t('MemberZone.submit') }}
              </el-button>
            </el-form>
          </el-tab-pane>
          <el-tab-pane :label="$t('MemberZone.records')">
            <p class="please_provide">
              {{ $t('MemberZone.record') }}
            </p>
            <el-table :data="records" style="width: 100%">
              <el-table-column :label="$t('MemberZone.event_info')" width="180">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.event_name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('MemberZone.organisation')" width="180">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.organisation }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column width="180" :label="$t('MemberZone.date')">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.date }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="180" :label="$t('MemberZone.location')">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.location }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('MemberZone.Status')" width="180">
                <template slot-scope="scope">
                  <div>
                    {{ recordRelations[scope.row.organisation_status] }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="180">
                <template slot-scope="scope">
                  <el-button v-if="recordRelations[scope.row.organisation_status] == recordRelations.w" @click="deleterecord(scope.row.id)"> {{ $t('MemberZone.DeleteButton') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <!-- saved pane -->
      <el-tab-pane :label="$t('MemberZone.saved')">
        <el-tabs>
          <el-tab-pane :label="$t('MemberZone.saved_coaches')">
            <div class="coach-profiles">
              <div class="coach-profile" v-for="(item, index) in saveProfiles" :key="index">
                <div
                  class="profile-bg"
                  :style="{
                    background: item.banner_url ? `url(${item.banner_url}) no-repeat 100%/100% !important` : `url(${bannerBg}) no-repeat 100%/100% !important`,
                  }"
                ></div>
                <div class="profile-bg1"></div>
                <div class="profile-info">
                  <div class="coach-info">
                    <div
                      class="head-portrait"
                      :style="{
                        backgroundImage: item.cover_url ? `url(${item.cover_url})` : `url(${headerBg})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                      }"
                    ></div>
                    <div class="person-info">
                      <div class="coach-name">
                        <span class="person-name">
                          {{ item.name }}
                        </span>
                        <!-- <span>
                                                    <img
                                                        class="icon"
                                                        src="../assets/img/2-Icons/icons_profile-medal.svg"
                                                        alt=""
                                                    />
                                                </span> -->
                        <!-- <span>
                                                    <img
                                                        class="icon"
                                                        src="../assets/img/2-Icons/icons_profile-trophy.svg"
                                                        alt=""
                                                    />
                                                </span> -->
                      </div>
                      <!-- <div class="coach-tit">
                                                {{
                                                    $t("CoachProfiles.position")
                                                }}
                                                {{ item.title }}
                                            </div> -->
                    </div>
                  </div>
                  <div class="stars">
                    <span>
                      <img v-for="(i, o) in 4" :key="o" class="star" src="../assets/img/2-Icons/icons_star.svg" alt="" />
                    </span>
                  </div>
                  <div class="main-sport">
                    <span> {{ $t('CoachProfiles.mainsport') }}</span>
                    <span v-for="(i, o) in item.main_sport" :key="o">
                      {{ sportType.find((one) => one.name_en == i) && sportType.find((one) => one.name_en == i).name }}
                    </span>
                  </div>
                  <div class="sports-container">
                    <div class="sport-btns">
                      <div class="sport-btn" v-for="(i, o) in item.Skills" :key="o">
                        {{ i.name && Skills.find((one) => one.value == i.name) ? Skills.find((one) => one.value == i.name).label : i.name }}
                      </div>
                    </div>
                  </div>
                  <div class="info-line"></div>
                  <div class="info-btn">
                    <button class="connect-btn" @click="toMore(item.id)">
                      {{ $t('CoachProfiles.btn2') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="paginationStyle">
              <el-pagination
                v-if="profilesPagination.total > 0"
                background
                @current-change="getProfoles"
                :current-page.sync="profilesPagination.currentPage"
                :page-size="profilesPagination.pagesize"
                layout="prev, pager, next"
                :total="profilesPagination.total"
              >
              </el-pagination>
              <div v-else>
                {{ $t('Jobs.nodata') }}
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('MemberZone.saved_opportunities')">
            <div class="job-list">
              <div class="jobs">
                <div class="opportunities" v-for="(item, index) in saveJobs" :key="index" @click="clickJob(item.id)">
                  <div
                    class="opportunities-bg"
                    :style="{
                      background: item.banner_url ? `url(${item.banner_url}) no-repeat 100%/100% !important` : `#adadad`,
                    }"
                  >
                    <div :class="item.type === 'p' ? 'opportunities-job' : 'opportunities-job opportunities-vol'">
                      {{ item.index }}
                    </div>
                  </div>
                  <div class="opportunities-info">
                    <p class="ihksport">
                      {{ item.organisation_name }}
                    </p>
                    <p class="info-active">
                      {{ item.event_name }}
                    </p>
                    <p class="info-sports">
                      <span>
                        <img class="info-img" src="../assets/img/2-Icons/icons_type-of-sports.svg" alt="" />
                      </span>
                      <span class="info-txt">
                        {{ sportType.find((one) => one.name_en == item.sports_type) && sportType.find((one) => one.name_en == item.sports_type).name }}
                      </span>
                    </p>
                    <p class="info-date">
                      <span>
                        <img class="info-img" src="../assets/img/2-Icons/icons_date.svg" alt="" />
                      </span>
                      <span class="info-txt">
                        {{ item.starting_date }} -
                        {{ item.ending_date }}
                      </span>
                    </p>
                    <p class="info-time">
                      <span>
                        <img class="info-img" src="../assets/img/2-Icons/icons_duration.svg" alt="" />
                      </span>
                      <span class="info-txt">
                        {{ item.class_duration_start }}
                        -
                        {{ item.class_duration_end }}
                      </span>
                    </p>
                    <p class="info-place">
                      <span>
                        <img class="info-img" src="../assets/img/2-Icons/icons_location.svg" alt="" />
                      </span>
                      <span class="info-txt">
                        {{ styleLang == 'cn' ? item.location && areas.find((one) => one.id == item.location).name_zh_tw : item.location && areas.find((one) => one.id == item.location).name_en }}
                      </span>
                    </p>
                    <p class="info-line"></p>
                    <p class="ihksport">
                      {{ $t('Jobs.placeholder15') }}
                    </p>
                    <p style="margin: 10px 0">
                      <button style="margin: 5px 0" class="target-btn" v-for="(i, o) in (item.target || '').split(',')" :key="o">
                        {{ i && Target.find((one) => one.value == i).label }}
                      </button>
                    </p>
                    <p class="post-date">
                      {{ $t('Jobs.post_date') }} :
                      {{ item.post_date }}
                    </p>
                    <p class="post-date">
                      {{ $t('Jobs.last_edited') }} :
                      {{ item.last_edited }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="paginationStyle">
                <el-pagination
                  v-if="savepagination.total > 0"
                  background
                  @current-change="getMySave"
                  :current-page.sync="savepagination.currentPage"
                  :page-size="savepagination.pagesize"
                  layout="prev, pager, next"
                  :total="savepagination.total"
                >
                </el-pagination>
                <div v-else>
                  {{ $t('Jobs.nodata') }}
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane :label="$t('MemberZone.my_coach')">
        <el-button @click="goPush()" type="primary" class="link_to">
          {{ $t('MemberZone.go_push') }}
        </el-button>
      </el-tab-pane>
      <el-tab-pane :label="$t('MemberZone.my_created')">
        <el-tabs>
          <el-tab-pane :label="$t('MemberZone.created_opportunities')">
            <el-table ref="singleTable" :data="createdTable" highlight-current-row style="width: 100%" @sort-change="created_sort" sortable="custom">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-table :data="props.row.JobApplies" stripe style="width: 100%">
                    <el-table-column prop="user_name" :label="$t('MemberZone.coach_details')"></el-table-column>
                    <el-table-column prop="profile" :label="$t('MemberZone.profile')">
                      <template slot-scope="scope">
                        <el-button size="mini" @click="toMore(scope.row.profile_id)">{{ $t('MemberZone.profile') }} </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column prop="email" :label="$t('MemberZone.Contact')"></el-table-column>
                    <el-table-column prop="status" :label="$t('MemberZone.Status')">
                      <template slot-scope="scope">
                        {{ statusRelations[scope.row.status] }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('MemberZone.confirm_connection')">
                      <template slot-scope="scope">
                        <el-button size="mini" :disabled="scope.row.status == 'c' || scope.row.status == 'r'" @click="handleInstitutionsApply(scope.row.id, 'c')">{{
                          $t('MemberZone.confirm')
                        }}</el-button>
                        <el-button size="mini" type="danger" :disabled="scope.row.status == 'c' || scope.row.status == 'r'" @click="handleInstitutionsApply(scope.row.id, 'r')">{{
                          $t('MemberZone.reject')
                        }}</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>

              <el-table-column type="index" width="50"> </el-table-column>
              <el-table-column property="index" sortable :label="$t('MemberZone.index')" width="120"> </el-table-column>
              <el-table-column :label="$t('MemberZone.copy')" width="120">
                <template slot-scope="scope">
                  <el-button size="mini" @click="clickCopyJob(scope.row.id)">{{ $t('MemberZone.copy') }} </el-button>
                </template>
              </el-table-column>
              <el-table-column :label="$t('MemberZone.edit')" width="120">
                <template slot-scope="scope">
                  <el-button size="mini" @click="clickJob(scope.row.id)">{{ $t('MemberZone.edit') }} </el-button>
                </template>
              </el-table-column>
              <el-table-column sortable property="event_name" :label="$t('MemberZone.event_info')" width="120"> </el-table-column>
              <el-table-column :label="$t('MemberZone.Status')" sortable property="apply_status">
                <template slot-scope="scope">
                  {{ applyRelations[scope.row.apply_status] }}
                </template>
              </el-table-column>
              <el-table-column sortable property="post_date" :label="$t('MemberZone.post_date')"> </el-table-column>
              <el-table-column sortable property="last_edited" :label="$t('MemberZone.last_edited')"> </el-table-column>
            </el-table>
            <div class="paginationStyle">
              <el-pagination
                v-if="createdPagination.total > 0"
                background
                @current-change="getMyJobApplies"
                :current-page.sync="createdPagination.currentPage"
                :page-size="createdPagination.pagesize"
                layout="prev, pager, next"
                :total="createdPagination.total"
              >
              </el-pagination>
              <div v-else>
                {{ $t('Jobs.nodata') }}
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('MemberZone.applied_opportunities')">
            <el-table :data="applyTable" style="width: 100%" @sort-change="applied_sort" sortable="custom">
              <el-table-column :label="$t('MemberZone.view')" width="180">
                <template slot-scope="scope">
                  <el-button size="mini" @click="clickJob(scope.row.job_id)">{{ $t('MemberZone.view') }} </el-button>
                </template>
              </el-table-column>
              <el-table-column prop="Job.event_name" :label="$t('MemberZone.event_info')" sortable width="180"> </el-table-column>
              <el-table-column prop="status" :label="$t('MemberZone.Status')" sortable>
                <template slot-scope="scope">
                  {{ statusRelations[scope.row.status] }}
                </template>
              </el-table-column>
              <el-table-column prop="status" :label="$t('MemberZone.confirm_connection')">
                <template slot-scope="scope" v-if="scope.row.status == 'c'">
                  <el-button size="mini" @click="handleUserApply(scope.row.id, 'c')">{{ $t('MemberZone.confirm') }}</el-button>
                  <el-button size="mini" type="danger" @click="handleUserApply(scope.row.id, 'r')">{{ $t('MemberZone.reject') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="paginationStyle">
              <el-pagination
                v-if="applyPagination.total > 0"
                background
                @current-change="getInviteJob"
                :current-page.sync="applyPagination.currentPage"
                :page-size="applyPagination.pagesize"
                layout="prev, pager, next"
                :total="applyPagination.total"
              >
              </el-pagination>
              <div v-else>
                {{ $t('Jobs.nodata') }}
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane :label="$t('MemberZone.notification')">
        <el-tabs>
          <el-tab-pane :label="$t('MemberZone.received_invitation')">
            <el-table :data="inviteJob" style="width: 100%">
              <el-table-column prop="Job.event_name" :label="$t('MemberZone.event_info')" width="180"> </el-table-column>
              <el-table-column prop="status" :label="$t('MemberZone.Status')">
                <template slot-scope="scope">
                  {{ inviteRelations[scope.row.status] }}
                </template>
              </el-table-column>
              <el-table-column prop="status" :label="$t('MemberZone.response')">
                <template slot-scope="scope">
                  <el-button size="mini" @click="handleInviteConfirm(scope.row.id, 'c')" :disabled="scope.row.status == 'c' || scope.row.status == 'r'">{{ $t('MemberZone.accept') }}</el-button>
                  <el-button size="mini" type="danger" @click="handleInviteConfirm(scope.row.id, 'r')" :disabled="scope.row.status == 'c' || scope.row.status == 'r'">{{
                    $t('MemberZone.reject')
                  }}</el-button>
                </template>
              </el-table-column>
              <el-table-column :label="$t('MemberZone.view')" width="180">
                <template slot-scope="scope">
                  <el-button size="mini" @click="clickJob(scope.row.job_id)">{{ $t('MemberZone.view') }} </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="paginationStyle">
              <el-pagination
                v-if="inviteJobPagination.total > 0"
                background
                @current-change="getCreated"
                :current-page.sync="inviteJobPagination.currentPage"
                :page-size="inviteJobPagination.pagesize"
                layout="prev, pager, next"
                :total="inviteJobPagination.total"
              >
              </el-pagination>
              <div v-else>
                {{ $t('Jobs.nodata') }}
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('MemberZone.my_invitation')">
            <el-table :data="inviteCoach" style="width: 100%">
              <el-table-column prop="CoachProfile.name" :label="$t('MemberZone.coach_name')" width="180"> </el-table-column>
              <el-table-column prop="status" :label="$t('MemberZone.Contact')">
                <template slot-scope="scope">
                  {{ inviteRelations[scope.row.status] }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('MemberZone.view_voach')" width="180">
                <template slot-scope="scope">
                  <el-button size="mini" @click="toMore(scope.row.profile_id)">{{ $t('MemberZone.view') }} </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="paginationStyle">
              <el-pagination
                v-if="inviteCoachPagination.total > 0"
                background
                @current-change="getApplyTable"
                :current-page.sync="inviteCoachPagination.currentPage"
                :page-size="inviteCoachPagination.pagesize"
                layout="prev, pager, next"
                :total="inviteCoachPagination.total"
              >
              </el-pagination>
              <div v-else>
                {{ $t('Jobs.nodata') }}
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
    <!-- // inspiring -->
    <div class="download-certification-1" style="display: none">
      <div class="margin-borders">
        <div class="border-line-0"></div>
        <div class="border-line-1"></div>
        <div class="blue-triangle"></div>
        <div class="orange-para"></div>
        <div class="light-para"></div>

        <div class="logos-cert">
          <img class="one" src="../assets/images/ihk-logo.png" alt="" />
          <img class="two" src="../assets/images/coach.png" alt="" />
          <img class="three" src="../assets/images/hkjcct_logo.png" alt="" />
        </div>
        <div class="margin">
          <p class="top">{{ $t('MemberZone.cert_top_cn') }}</p>
          <p class="top">{{ $t('MemberZone.cert_top') }}</p>
          <div class="user">
            {{ $t(ruleForm1.name_en) }}
          </div>
          <p class="txt1">{{ $t('MemberZone.cert_txt1_cn') }}</p>
          <p class="txt1">{{ $t('MemberZone.cert_txt1') }}</p>
          <p class="txt2">{{ $t('MemberZone.cert_txt2_cn') }}</p>
          <p class="txt2">{{ $t('MemberZone.cert_txt2') }}</p>
          <p class="txt3-shape"></p>
          <p class="txt3">{{ $t('MemberZone.cert_txt3') }}</p>
          <p class="txt4">{{ $t(completedTimeInspire) }}</p>
          <div class="border-line-3"></div>
          <div class="border-line-4"></div>
          <div class="right-triangle"></div>
          <div class="right-orange"></div>
          <div class="right-light"></div>
        </div>
      </div>
    </div>
    <!-- // safeguarding -->
    <div class="download-certification" style="display: none">
      <div class="margin-borders">
        <div class="border-line-0"></div>
        <div class="border-line-1"></div>
        <div class="blue-triangle"></div>
        <div class="orange-para"></div>
        <div class="light-para"></div>

        <div class="logos-cert">
          <img class="one" src="../assets/images/ihk-logo.png" alt="" />
          <img class="four" src="../assets/images/Donation_tow.png" alt="" />
        </div>
        <div class="margin">
          <p class="top">{{ $t('MemberZone.safe_top_cn') }}</p>
          <p class="top">{{ $t('MemberZone.safe_top') }}</p>
          <div class="user">
            {{ $t(ruleForm1.name_en) }}
          </div>
          <p class="txt1">{{ $t('MemberZone.safe_txt1_cn') }}</p>
          <p class="txt1">{{ $t('MemberZone.safe_txt1') }}</p>
          <p class="txt2">{{ $t('MemberZone.safe_txt2_cn') }}</p>
          <p class="txt2">{{ $t('MemberZone.safe_txt2') }}</p>
          <p class="txt3-shape"></p>
          <p class="txt3">{{ $t('MemberZone.safe_txt3') }}</p>
          <p class="txt4">{{ $t(completedTimeSafeguarding) }}</p>
          <div class="border-line-3"></div>
          <div class="border-line-4"></div>
          <div class="right-triangle"></div>
          <div class="right-orange"></div>
          <div class="right-light"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserProfile, updateUserProfile, getCourseReport, submitRecord, deleteRecord, applySharingEvent } from '@/api/memberUser'
import { getSaveList, getMyList, GetArea, GetTime } from '@/api/opportunities'
import { getSportTypes, getSavedProfiles } from '@/api/coachProfile'
import { getInviteCoach, getUserInviteJob, getApplyJob, getMyJob, copyJob, putInviteConfirm, putInstitutionsApply, putUserApply, getRecord } from '@/api/member'
import { S3Api } from '@/api/fileUtility'
import jspdf from 'jspdf'
import html2canvas from 'html2canvas'

export default {
  name: 'zone',
  data() {
    return {
      screenWidth: window.document.body.clientWidth,
      dialogFormVisible: false,
      ruleForm1: {
        email: '',
        password: '',
        repassword: '',
        name_en: '',
        name_zh_tw: '',
        age_range: '',
        birth_of_date: null,
        gender: '',
        other_gender: '',
        area_code: '',
        mobile: '',
        address: '',
        capacity: [],
        other_capacity: '',
        apply_reason: '',
        way: [],
        other_way: '',
        interested_content: [],
        education_level: '',
        other_education_level: '',
        main_clients: [],
        other_min_client: '',
      },
      ruleForm2: {
        event_name: '',
        organisation: '',
        name: '',
        email: '',
        date: '',
        time_start: '',
        time_end: '',
        location: '',
        photo: '',
        i: '',
        s: '',
        number_of_beneficiaries: '',
        mobile: '',
        area_code: '',
      },
      time: null,
      finishedCourses: [],
      progressingCourses: [],
      agreeTxt: '',
      input: '',
      active: 0,
      btnChangeEnable: true,
      loading: true,
      myJobs: [],
      saveJobs: [],
      saveProfiles: [],
      createdPagination: {
        currentPage: 1, // 当前页码
        pagesize: 10, // 每页显示的行数
        tableData: this.createdTable,
        total: 0,
      },
      createdTableSort: {
        sortby: '',
        sort_direction: 'asc',
      },
      applyPagination: {
        currentPage: 1, // 当前页码
        pagesize: 10, // 每页显示的行数
        tableData: this.applyTable,
        total: 0,
      },
      applyTableSort: {
        sortby: '',
        sort_direction: 'asc',
      },
      savepagination: {
        currentPage: 1, // 当前页码
        pagesize: 9, // 每页显示的行数
        tableData: this.saveJobs,
        total: 0,
      },
      profilesPagination: {
        currentPage: 1, // 当前页码
        pagesize: 9, // 每页显示的行数
        tableData: this.saveProfiles,
        total: 0,
      },
      inviteCoachPagination: {
        currentPage: 1, // 当前页码
        pagesize: 10, // 每页显示的行数
        tableData: this.inviteCoach,
        total: 0,
      },
      inviteJobPagination: {
        currentPage: 1, // 当前页码
        pagesize: 10, // 每页显示的行数
        tableData: this.inviteJob,
        total: 0,
      },
      sportType: [],
      areas: [],
      styleLang: 'cn',
      certList: [
        {
          top_cn: 'cert_top_cn',
          top: 'cert_top',
          txt1_cn: 'cert_txt1_cn',
          txt1: 'cert_txt1',
          txt2_cn: 'cert_txt2_cn',
          txt2: 'cert_txt2',
          txt3_cn: 'cert_txt3_cn',
          txt3: 'cert_txt3',
        },
      ],
      certList2: [
        {
          top: 'cert_top',
          txt1: 'cert_txt_1',
          txt2: 'cert_txt_2',
          txt3: 'cert_txt_3',
        },
      ],

      Target: [
        {
          value: 'Primary School',
          label: this.$t('CreateProfiles.coachingTarget1'),
        },
        {
          value: 'Secondary School',
          label: this.$t('CreateProfiles.coachingTarget2'),
        },
        {
          value: 'Parent-child',
          label: this.$t('CreateProfiles.coachingTarget3'),
        },
        {
          value: 'Elderly',
          label: this.$t('CreateProfiles.coachingTarget4'),
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      Skills: [
        {
          value: 'Motivate',
          label: this.$t('Skills.Motivate'),
        },
        {
          value: 'Passionate_about_sports',
          label: this.$t('Skills.Passionate_about_sports'),
        },
        {
          value: 'Casual',
          label: this.$t('Skills.Casual'),
        },
        {
          value: 'Creative_Thinking',
          label: this.$t('Skills.Creative_Thinking'),
        },
        {
          value: 'Passionate_about_teaching',
          label: this.$t('Skills.Passionate_about_teaching'),
        },
        {
          value: 'Flexibility',
          label: this.$t('Skills.Flexibility'),
        },
        {
          value: 'Confidence',
          label: this.$t('Skills.Confidence'),
        },
        {
          value: 'With_a_clearly',
          label: this.$t('Skills.With_a_clearly'),
        },
        {
          value: 'Goal_oriented',
          label: this.$t('Skills.Goal_oriented'),
        },
        {
          value: 'Knowledgeable',
          label: this.$t('Skills.Knowledgeable'),
        },
        {
          value: 'Careful',
          label: this.$t('Skills.Careful'),
        },
        {
          value: 'Respectful',
          label: this.$t('Skills.Respectful'),
        },
        {
          value: 'Patient',
          label: this.$t('Skills.Patient'),
        },
        {
          value: 'Chatty',
          label: this.$t('Skills.Chatty'),
        },
        {
          value: 'Approachable',
          label: this.$t('Skills.Approachable'),
        },
        {
          value: 'Fair',
          label: this.$t('Skills.Fair'),
        },
        {
          value: 'Logical',
          label: this.$t('Skills.Logical'),
        },
        {
          value: 'Supportive',
          label: this.$t('Skills.Supportive'),
        },
        {
          value: 'Persuasive',
          label: this.$t('Skills.Persuasive'),
        },
        {
          value: 'Calm',
          label: this.$t('Skills.Calm'),
        },
        {
          value: 'Curious',
          label: this.$t('Skills.Curious'),
        },
        {
          value: 'Principled',
          label: this.$t('Skills.Principled'),
        },
        {
          value: 'Systemic',
          label: this.$t('Skills.Systemic'),
        },
        {
          value: 'Adventurous',
          label: this.$t('Skills.Adventurous'),
        },
        {
          value: 'Positive',
          label: this.$t('Skills.Positive'),
        },
        {
          value: 'Optimistic',
          label: this.$t('Skills.Optimistic'),
        },
        {
          value: 'Leadership',
          label: this.$t('Skills.Leadership'),
        },
        {
          value: 'Inspiring',
          label: this.$t('Skills.Inspiring'),
        },
        {
          value: 'Professional',
          label: this.$t('Skills.Professional'),
        },
        {
          value: 'Good_listener',
          label: this.$t('Skills.Good_listener'),
        },
        {
          value: 'Reliable',
          label: this.$t('Skills.Reliable'),
        },
        {
          value: 'Attention_to_detail',
          label: this.$t('Skills.Attention_to_detail'),
        },
        {
          value: 'Self_discipline',
          label: this.$t('Skills.Self_discipline'),
        },
        {
          value: 'Good_communication_skills',
          label: this.$t('Skills.Good_communication_skills'),
        },
        {
          value: 'Harmonious',
          label: this.$t('Skills.Harmonious'),
        },
        {
          value: 'Friendly',
          label: this.$t('Skills.Friendly'),
        },
        {
          value: 'Perseverance',
          label: this.$t('Skills.Perseverance'),
        },
        {
          value: 'Clear_instructions_and_expressions',
          label: this.$t('Skills.Clear_instructions_and_expressions'),
        },
        {
          value: 'Accept_people_with_different_abilities',
          label: this.$t('Skills.Accept_people_with_different_abilities'),
        },
        {
          value: 'Rapid_response',
          label: this.$t('Skills.Rapid_response'),
        },
        {
          value: 'Kind',
          label: this.$t('Skills.Kind'),
        },
        {
          value: 'Tolerant',
          label: this.$t('Skills.Tolerant'),
        },
        {
          value: 'Strict_image',
          label: this.$t('Skills.Strict_image'),
        },
      ],
      skillLevel: [
        {
          value: 'No Relevant Experiences',
          label: this.$t('Jobs.skillLevel1'),
        },
        {
          value: 'Introductory',
          label: this.$t('Jobs.skillLevel2'),
        },
        {
          value: 'Intermediate',
          label: this.$t('Jobs.skillLevel3'),
        },
        {
          value: 'Advanced',
          label: this.$t('Jobs.skillLevel4'),
        },
        {
          value: 'Professional',
          label: this.$t('Jobs.skillLevel5'),
        },
      ],
      mediumOfInstruction: [
        {
          value: 'Cantonese',
          label: this.$t('Jobs.mediumOfInstruction1'),
        },
        {
          value: 'Putonghua',
          label: this.$t('Jobs.mediumOfInstruction2'),
        },
        {
          value: 'English',
          label: this.$t('Jobs.mediumOfInstruction3'),
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      eventsTypes: [
        {
          value: 'Regular Training Course',
          label: this.$t('Jobs.eventsTypes1'),
        },
        {
          value: 'One-off Event',
          label: this.$t('Jobs.eventsTypes2'),
        },
      ],
      studyTypes: {
        not_started: this.$t('MemberZone.not_started'),
        started: this.$t('MemberZone.started'),
        progressed: this.$t('MemberZone.progressed'),
        passed: this.$t('MemberZone.passed'),
      },
      classFrequency: [
        {
          value: 'Every Monday',
          label: this.$t('Jobs.classFrequency1'),
        },
        {
          value: 'Every Tuesday',
          label: this.$t('Jobs.classFrequency2'),
        },
        {
          value: 'Every Wednesday',
          label: this.$t('Jobs.classFrequency3'),
        },
        {
          value: 'Every Thursday',
          label: this.$t('Jobs.classFrequency4'),
        },
        {
          value: 'Every Friday',
          label: this.$t('Jobs.classFrequency5'),
        },
        {
          value: 'Every Saturday',
          label: this.$t('Jobs.classFrequency6'),
        },
        {
          value: 'Every Sunday',
          label: this.$t('Jobs.classFrequency7'),
        },
        {
          value: 'Everyday',
          label: this.$t('Jobs.classFrequency8'),
        },
        {
          value: 'Every Week',
          label: this.$t('Jobs.classFrequency9'),
        },
        {
          value: 'Every Second Week',
          label: this.$t('Jobs.classFrequency10'),
        },
        {
          value: 'Every Month',
          label: this.$t('Jobs.classFrequency11'),
        },
        {
          value: 'To Be Confirmed',
          label: this.$t('Jobs.classFrequency12'),
        },
      ],
      file1: '',
      isFileChange: false,
      fileList1: [],
      BtnLoading: false,
      createdTable: [],
      applyTable: [],
      inviteJob: [],
      inviteCoach: [],
      records: [],
      statusRelations: {
        w: this.$t('MemberZone.wait'),
        c: this.$t('MemberZone.connection'),
        a: this.$t('MemberZone.connection_successed'),
        r: this.$t('MemberZone.rejected'),
      },
      applyRelations: {
        w: this.$t('MemberZone.wait'),
        a: this.$t('MemberZone.connection'),
        f: this.$t('MemberZone.connection_successed'),
      },
      inviteRelations: {
        w: this.$t('MemberZone.w'),
        c: this.$t('MemberZone.c1'),
        r: this.$t('MemberZone.r'),
      },
      recordRelations: {
        w: this.$t('MemberZone.w'),
        c: this.$t('MemberZone.confirmed'),
      },
      deletebtn: {
        w: this.$t('MemberZone.DeleteButton'),
      },
      currentRow: null,
      isSafeguardingFinished: false,
      isInspireCompleted: false,
      completedTimeSafeguarding: '',
      completedTimeInspire: '',
      chcapcaity: false,
    }
  },
  computed: {
    labelWidth() {
      return window.localStorage.getItem('locale') === 'en' ? '120px' : '120px'
    },
  },
  mounted() {
    this.getSports()
    this.getArea()
    this.getRecord()
    this.getMySave()
    this.getProfoles()
    this.getCreated()
    this.getApplyTable()
    this.getInviteJob()
    this.getMyJobApplies()
    this.getTime()
    S3Api.refreshS3KeyAction().then((response) => {
      S3Api.saveS3KeyAction(response)
    })
  },
  methods: {
    created_sort(sorted) {
      switch (sorted.prop) {
        case 'post_date':
          this.createdTableSort.sortby = 'createdAt'
          break
        case 'last_edited':
          this.createdTableSort.sortby = 'last_edited'
          break

        default:
          this.createdTableSort.sortby = sorted.prop
          break
      }
      let direction = ''
      if (sorted.order) {
        direction = sorted.order === 'ascending"' ? 'asc' : 'desc'
      }
      this.createdTableSort.sort_direction = direction
      this.getMyJobApplies()
    },
    applied_sort(sorted) {
      switch (sorted.prop) {
        case 'Job.event_name':
          this.applyTableSort.sortby = 'job.event_name'
          break

        default:
          this.applyTableSort.sortby = sorted.prop
          break
      }
      let direction = ''
      if (sorted.order) {
        direction = sorted.order === 'ascending"' ? 'asc' : 'desc'
      }
      this.applyTableSort.sort_direction = direction
      this.getInviteJob()
    },
    getTime() {
      GetTime('s').then((res) => {
        this.completedTimeSafeguarding = res.data == 'Invalid date' ? '' : res.data
      })
      GetTime('i').then((res) => {
        this.completedTimeInspire = res.data == 'Invalid date' ? '' : res.data
      })
    },
    getRecord() {
      getRecord().then((res) => {
        this.records = res.data.data
      })
    },
    certification_one() {
      var source = window.document.getElementsByClassName('download-certification-1')[0]

      html2canvas(source, {
        onclone: function (clonedDoc) {
          clonedDoc.getElementsByClassName('download-certification-1')[0].style.display = 'block'
        },
      }).then(function (canvas) {
        var img = canvas.toDataURL('image/png')
        var doc = new jspdf('l')
        var width = doc.internal.pageSize.getWidth()
        var height = doc.internal.pageSize.getHeight()
        doc.addImage(img, 'JPEG', 0, 0, width, height)
        doc.save('Inspire Certificate.pdf')
      })
    },
    certification() {
      var source = window.document.getElementsByClassName('download-certification')[0]

      html2canvas(source, {
        onclone: function (clonedDoc) {
          clonedDoc.getElementsByClassName('download-certification')[0].style.display = 'block'
        },
      }).then(function (canvas) {
        var img = canvas.toDataURL('image/png')
        var doc = new jspdf('l')
        var width = doc.internal.pageSize.getWidth()
        var height = doc.internal.pageSize.getHeight()
        doc.addImage(img, 'JPEG', 0, 0, width, height)
        doc.save('Safeguarding Certificate.pdf')
      })
    },
    handleCopy(id) {
      copyJob(id).then((res) => {
        res.data.data = this.$message({
          message: 'Copied Sucessfully, Wait for confirmation',
        })
      })
    },
    handleInstitutionsApply(id, status) {
      putInstitutionsApply(id, status).then((res) => {
        console.log(res)
        this.getMyJobApplies()
      })
    },
    handleUserApply(id, status) {
      putUserApply(id, status).then((res) => {
        console.log(res)
        this.getInviteJob()
      })
    },
    handleInviteConfirm(id, status) {
      putInviteConfirm(id, status).then((res) => {
        console.log(res)
        this.getCreated()
      })
    },
    getCreated() {
      getUserInviteJob(this.inviteJobPagination.currentPage).then((res) => {
        this.inviteJob = res.data.data
        this.inviteJobPagination.total = res.data.total
      })
    },
    getApplyTable() {
      getInviteCoach(this.inviteCoachPagination.currentPage).then((res) => {
        this.inviteCoach = res.data.data
        this.inviteCoachPagination.total = res.data.total
      })
    },
    getInviteJob() {
      getApplyJob(this.applyPagination.currentPage, this.applyTableSort.sortby, this.applyTableSort.sort_direction).then((res) => {
        this.applyTable = res.data.data
        this.applyPagination.total = res.data.total
      })
    },
    getMyJobApplies() {
      getMyJob(this.createdPagination.currentPage, this.createdTableSort.sortby, this.createdTableSort.sort_direction).then((res) => {
        this.createdTable = res.data.data
        this.createdPagination.total = res.data.total
      })
    },

    getArea() {
      let area
      GetArea().then((res) => {
        area = res.data
        area.map((one) => {
          this.areas = this.areas.concat(one.Areas)
        })
      })
    },
    getSports() {
      getSportTypes().then((res) => {
        this.sportType = res.data.data
      })
    },
    getProfoles() {
      getSavedProfiles(this.profilesPagination.currentPage).then((res) => {
        this.saveProfiles = res.data.data
        this.profilesPagination.total = res.data.total
      })
    },
    getMySave() {
      getSaveList(this.savepagination.currentPage).then((res) => {
        this.saveJobs = res.data.data
        this.savepagination.total = res.data.total
      })
    },
    // getMyJobs() {
    //     getMyList(this.pagination.currentPage).then((res) => {
    //         this.myJobs = res.data.data;
    //         this.pagination.total = res.data.total;
    //     });
    // },
    clickJob(id) {
      // console.log(id)
      this.$router.push({
        path: '/coach-connect/create-job',
        query: { id: id },
      })
    },

    clickCopyJob(id) {
      // console.log(id)
      this.$router.push({
        path: '/coach-connect/copy-job',
        query: { id: id },
      })
    },
    goPush() {
      this.$router.push({
        path: '/coach-connect/create-profiles',
      })
    },
    toMore(id) {
      console.log(id)
      this.$router.push({ path: '/coach-connect/create-profiles', query: { id: id } })
    },
    upload() {
      this.dialogFormVisible = true
    },
    deleterecord(id) {
      deleteRecord(id)
    },
    submit() {
      let params = {
        event_name: this.ruleForm2.event_name,
        organisation: this.ruleForm2.organisation,
        email: this.ruleForm2.email,
        name: this.ruleForm2.name,
        date: this.ruleForm2.date,
        time_start: this.ruleForm2.time_start,
        time_end: this.ruleForm2.time_end,
        location: this.ruleForm2.location,
        photo: this.ruleForm2.photo,
        number_of_beneficiaries: this.ruleForm2.number_of_beneficiaries,
      }
      submitRecord(params)
        .then((res) => {
          if (res.result === 'success') {
            let shareEvent = {
              event_id: res.data.id,
              event_name: this.ruleForm2.event_name,
              event_date: this.ruleForm2.date,
              event_time: this.ruleForm2.time_start,
            }
            applySharingEvent(shareEvent).then((data) => {
              this.$message({
                message: this.$t('MemberZone.notif'),
                type: 'success',
              })
            })
          }
        })
        .catch(() => {
          this.$message({
            message: '提交失敗',
            type: 'error',
          })
        })
    },
    handleRemove1(file) {
      this.fileList1.splice(this.fileList1.indexOf(file), 1)
      this.ruleForm2.photo = ''
      this.file1 = ''
    },
    handleChange1(file, list) {
      this.isFileChange = true
    },
    beforeUpload1(file) {
      this.file1 = file
      this.fileList1.push(file)
    },
    uploadPhotos1() {
      S3Api.uploadPhoto(this.file1)
        .then((res) => {
          this.ruleForm2.photo = res.Key
          this.fileList1 = []
          this.BtnLoading = false
          this.dialogFormVisible = false
        })
        .catch(() => {})
    },
    uploadPic1() {
      this.BtnLoading = true
      this.$refs.upload1.submit()
    },
    exitOut() {
      window.sessionStorage.removeItem('token')
      this.$router.push('/')
      location.reload()
      this.$message({
        message: '登出成功',
        type: 'success',
      })
    },
    checkcapcaity() {
      if (this.ruleForm1.capacity == '') {
        this.chcapcaity = false
        this.$message({
          message: '我的身份不能空白',
          type: 'error',
        })
        return false
      } else {
        this.chcapcaity = true
        return true
      }
    },
    //修改账户信息
    updateMemberInfo() {
      if (this.checkcapcaity() == true) {
        this.loading = true
        const data = {
          name_en: this.ruleForm1.name_en,
          name_zh_tw: this.ruleForm1.name_zh_tw,
          age_range: this.ruleForm1.age_range,
          birth_of_date: this.ruleForm1.birth_of_date ? this.ruleForm1.birth_of_date.substring(0, 10) : null,
          gender: this.ruleForm1.gender,
          other_gender: this.ruleForm1.other_gender,
          capacity: this.ruleForm1.capacity,
          other_capacity: this.ruleForm1.other_capacity,
          apply_reason: this.ruleForm1.apply_reason,
          way: this.ruleForm1.way,
          other_way: this.ruleForm1.other_way,
          mobile: this.ruleForm1.mobile,
          address: this.ruleForm1.address,
          interested_content: this.ruleForm1.interested_content,
          education_level: this.ruleForm1.education_level,
          main_clients: this.ruleForm1.main_clients,
        }

        updateUserProfile(data)
          .then((res) => {
            if (res.result === 'success') {
              this.$message({
                message: '修改成功',
                type: 'success',
              })
              this.getMemberInfo()
            }
          })
          .catch(() => {
            this.$message({
              message: '修改失敗',
              type: 'error',
            })
            this.loading = false
          })
      }
    },
    //获取账户信息
    getMemberInfo() {
      getUserProfile()
        .then(async (res) => {
          if (res.result === 'success') {
            this.ruleForm1 = res.data
            this.ruleForm2.name_en = res.data.name_en
            this.ruleForm2.name_zh_tw = res.data.name_zh_tw
            this.ruleForm2.gender = res.data.gender
            this.ruleForm2.userEmail = res.data.email
            this.ruleForm2.address = res.data.address
            this.ruleForm1.way = []
            if (this.ruleForm1.name_zh_tw == '[EMPTY]') {
              this.ruleForm1.name_zh_tw = ''
            }
            if (this.ruleForm2.name_zh_tw == '[EMPTY]') {
              this.ruleForm2.name_zh_tw = ''
            }
            if (this.ruleForm1.address == '[EMPTY]') {
              this.ruleForm1.address = ''
            }
            if (this.ruleForm2.address == '[EMPTY]') {
              this.ruleForm2.address = ''
            }
            if (this.ruleForm1.capacity === null) {
              this.ruleForm1.capacity = []
            }
            await this.getCourseReport()
            this.loading = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          /*sessionStorage.removeItem("token");
          this.$router.push("/");
          location.reload();*/
        })
    },
    getCourseReport: async function () {
      let res
      res = await getCourseReport()
      res = res.data
      this.finishedCourses = []
      this.progressingCourses = []
      for (let i = 0; i < res.inspiration.length; i++) {
        this.finishedCourses.push({
          num: i + 1,
          stuName: this.$i18n.locale == 'en' ? res.inspiration[i].Course.title_en : res.inspiration[i].Course.title_zh_tw,
          state: res.inspiration[i].status ? res.inspiration[i].status : 'not_started',
          achieve: res.inspiration[i].score || 0,
          score: res.inspiration[i].score,
        })
      }

      for (let i = 0; i < res.safeguarding.length; i++) {
        this.progressingCourses.push({
          num: i + 1,
          stuName: this.$i18n.locale == 'en' ? res.safeguarding[i].Course.title_en : res.safeguarding[i].Course.title_zh_tw,
          state: res.safeguarding[i].status ? res.safeguarding[i].status : 'not_started',
          achieve: res.safeguarding[i].score || 0,
          score: res.safeguarding[i].score,
        })
      }

      let combinedArrayProgressing = [...this.progressingCourses]
      let combinedArrayFinshed = [...this.finishedCourses]

      let ansSafeguarding = combinedArrayProgressing.every((data) => data.state === 'passed')
      let ansInspire = combinedArrayFinshed.every((data) => data.state === 'passed')
      this.isSafeguardingFinished = ansSafeguarding
      this.isInspireCompleted = ansInspire
    },
  },
  created() {
    this.getMemberInfo()
    this.styleLang = window.localStorage.getItem('locale') || 'cn'
    console.log('RUNNING ON SAME PAGE')
  },
}
</script>
<style lang="scss" scoped>
.paginationStyle {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0 50px 0;
}
::v-deep {
  .el-table td.el-table__cell div {
    word-break: break-word;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #f15922; // 进行修改选中项背景和字体
    color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #f15922;
  }
  .el-tabs__item.is-left {
    text-align: left;
    line-height: 80px;
    height: 80px;
  }
  .el-tabs--border-card {
    border-color: #f15922;
    box-shadow: none;
    border-radius: 10px;
  }
  .el-tabs--border-card > .el-tabs__header {
    background-color: #f15922;
    opacity: 0.7;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #fff;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: #fff;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #f15922;
  }
  .el-tabs__item.is-active {
    color: #f15922;
  }
  .el-tabs__item:hover {
    color: #f15922;
  }
  .el-tabs__active-bar {
    background-color: #f15922;
  }
}
.el-button,
.el-button:focus,
.el-button:active {
  background-color: #f15922;
  color: #fff;
  border: 0;
}
.el-button:hover {
  background-color: #f15922;
  color: #fff;
  border: 0;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  cursor: not-allowed;
  background-image: none;
  color: #bcbec2;
  background-color: #f4f4f5;
  border-color: #e9e9eb;
}
.stu-tit {
  font-size: 24px;
  margin-top: 50px;
}
.register-bg {
  letter-spacing: 0px;
  width: 100%;
  margin: 0 auto;
  .account-information {
    margin: 0 auto;
    .el-form-item {
      text-align: left;

      .el-form-item__label {
        text-align: left !important;
      }
      .el-input {
        width: 90%;
      }
    }
    .title {
      font-size: 24px;
      margin: 5% auto;
    }
    .sex {
      .sex_group {
        text-align: left;
      }
    }
    .identity {
      text-align: left;
      .el-checkbox__label {
        .other {
          width: 70%;
          margin-left: 3px;
        }
      }
    }
    .stu-progress {
      padding-left: 50px;
      margin-bottom: 30px;
      text-align: center;
      .stu-txt {
        font-size: 18px;
        text-align: left;
        margin: 20px 0;
        display: block;
      }
      .stu-done {
        margin-top: 40px;
      }
      .stu-unpush {
        margin-top: 40px;
      }
      .download-btn {
        margin-top: 50px;
      }
    }
  }
  // volunteer part
  .volunteer_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .volunteer_section_marker {
      background-color: #f15922;
      padding: 20px;
      margin-block: 30px;
      color: white;
      max-width: 500px;
    }

    ul {
      align-self: flex-start;
      text-align: left;
      margin-left: 40px;
      list-style: disc;
    }

    .volunteer_section_example {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      margin-top: 50px;
      gap: 20px;

      .volunteer_section_example--item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .volunteer_example_header {
          font-weight: bold;
          padding: 10px;
          background-color: #f15922;
          color: white;
          max-width: 100px;
        }
        p {
          max-width: 50ch;
        }
      }
    }
  }
}
.please_provide {
  font-size: 24px;
  text-align: left;
  padding: 50px 0 50px 10px;
}
.link_to {
  text-align: left;
  margin: 100px 0;
}
.job-list {
  padding: 50px 5px 50px 5px;
  .jobs {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .opportunities {
      width: 32%;
      // width: 435px;
      margin-bottom: 66px;
      background: #ffffff;
      box-shadow: 0px 0px 20px #d1d4eb;
      border-radius: 8px;
      position: relative;
      height: 750px;
      text-align: left;
      overflow: hidden;
      .opportunities-bg {
        background-color: #adadad;
        height: 30%;
        position: relative;
      }
      .opportunities-info {
        padding-left: 33px;
        overflow: hidden;
        .info-line {
          margin-top: 33px;
          width: 95%;
          height: 0px;
          border: 1px solid #d1d4eb;
        }
        .info-img {
          width: 18px;
          height: 18px;
        }
        .info-txt {
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
    .opportunities:hover {
      cursor: pointer;
    }
  }
}
.jobs:after {
  content: '';
  width: 32%;
}
.opportunities-job {
  position: absolute;
  bottom: 0;
  width: 1.99rem;
  height: 0.5rem;
  background: #8f98dc;
  transform: skew(-15deg) translateX(-3%);
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: #ffffff;
  padding-left: 10px;
  overflow: hidden;
}
.opportunities-vol {
  background: #f15922;
}
.ihksport {
  margin: 33px 0 7px 0;
  // width: 91px;
  text-align: left;
  min-height: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #8f98dc;
}
.info-active {
  margin-bottom: 24px;
  // width: 226px;
  text-align: left;
  min-height: 28px;
  font-size: 28px;
  font-weight: bold;
  line-height: 46px;
  color: #28327b;
}
.target-btn {
  // margin: 10px 0;
  width: 160px;
  height: 30px;
  background: #8f98dc;
  border: 0;
  border-radius: 15px;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  margin-right: 5px;
}
.post-date {
  margin: 5px 10px 5px 0;
  // margin-top: 5px;
  text-align: end;
  color: #999;
  font-size: 16px;
}
.coach-profiles {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .coach-profile {
    width: 49%;
    overflow: hidden;
  }
  .person-info {
    margin-left: 45%;
  }
}
.coach-profiles {
  margin: 0 auto;
  padding: 50px 5px 50px 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .coach-profile {
    width: 32%;
    min-height: 500px;
    margin-bottom: 66px;
    background: #ffffff;
    box-shadow: 0px 0px 20px #d1d4eb;
    border-radius: 8px;
    position: relative;
    .profile-bg {
      // background: url("../assets/img/1-Box-Images/images_seminar.png")
      //     no-repeat 100%/100% !important ;
      height: 166px;
      border-radius: 8px 8px 0px 0px;
    }
    .profile-bg1 {
      width: 100%;
      height: 85px;
      position: absolute;
      top: 81px;
      background: linear-gradient(180deg, rgba(255, 0, 0, 0) 0%, #808080 100%);
    }
    .profile-info {
      padding: 0 20px;
      .coach-info {
        display: flex;
        position: relative;
        .head-portrait {
          position: absolute;
          top: -30px;
          width: 125px;
          height: 124px;
          border: 3px solid #d1d4eb;
          border-radius: 50%;
          // background: url("../../src/assets/img/1-Box-Images/images_profile.png")
          //     no-repeat 100%/100%;
        }
        .person-info {
          margin-left: 45%;
          margin-top: 15px;
          .coach-name {
            font-size: 22px;
            font-weight: bold;
            line-height: 28px;
            color: #28327b;
            display: flex;
            justify-content: center;
            .icon {
              width: 34px;
              height: 34px;
              background: #fbf7f6;
              border-radius: 50%;
              margin: 0 5px;
            }
          }
          .coach-tit {
            width: 100%;
            text-align: left;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            color: #898989;
          }
        }
      }
      .stars {
        text-align: left;
        margin-top: 80px;
        .star {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin: 0 5px;
        }
      }
      .main-sport {
        text-align: left;
        min-height: 80px;
        font-size: 20px;
        font-weight: 600;
        line-height: 36px;
        color: #8f98dc;
      }
      .sports-container {
        height: 120px;
        .sport-btns {
          display: flex;
          flex-wrap: wrap;
          min-height: 50px;
          .sport-btn {
            margin: 5px 0;
            width: 119px;
            height: 30px;
            background: #8f98dc;
            border: 0;
            border-radius: 15px;
            font-size: 14px;
            line-height: 30px;
            color: #ffffff;
            text-align: center;
            margin-right: 5px;
          }
        }
      }
      .info-line {
        margin: 20px 0;
        width: 100%;
        height: 0px;
        border: 1px solid #d1d4eb;
      }
      .info-btn {
        width: 100%;
        display: flex;
        margin: 7px 0;
        justify-content: space-between;
      }
      .save-btn {
        width: 185px;
        height: 60px;
        border: 1px solid #f15922;
        border-radius: 4px;
        color: #f15922;
        background: #fff;
      }
      .connect-btn {
        width: 100%;
        height: 60px;
        background: #f15922;
        color: #fff;
        border: 1px solid #f15922;
        border-radius: 4px;
        margin: 5px 0 20px 0;
      }
    }
  }
}
//download pdf
.download-certification-1 {
  margin: 0 auto;
  width: 100%;
  max-height: 1100px;
  .logos-cert {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin-top: -210px;
    margin-bottom: 80px;
    height: 30%;
    width: 100%;
    .one {
      margin-top: -29px;
      height: 120px;
      width: 300px;
    }
    .two {
      margin-top: -23px;
      height: 140px;
      width: 400px;
    }
    .three {
      height: 90px;
      width: 400px;
    }
  }
  .border-line-0 {
    border-top: 2px solid #d5eae3;
    width: 1320px;
    margin-left: 280px;
    padding: 3px;
    margin-top: 45px;
    position: absolute;
    border-right: 2px solid #d5eae3;
    height: 550px;
  }

  .border-line-1 {
    border-top: 5px solid #d5eae3;
    width: 1270px;
    margin-left: 300px;
    padding: 3px;
    margin-top: 70px;
    position: absolute;
    border-right: 5px solid #d5eae3;
    height: 550px;
  }
  .border-line-3 {
    border-bottom: 5px solid #d5eae3;
    width: 1310px;
    position: absolute;
    margin-top: -400px;
    margin-right: 150px;
    border-left: 5px solid #d5eae3;
    height: 550px;
  }
  .border-line-4 {
    border-bottom: 2px solid #d5eae3;
    width: 1320px;
    position: absolute;
    margin-top: -350px;
    margin-right: 200px;
    border-left: 2px solid #d5eae3;
    height: 570px;
  }

  .blue-triangle {
    position: relative;
    width: 0;
    height: 0px;
    border-top: 350px solid #28327b;
    border-right: 180px solid transparent;
  }
  .orange-para {
    position: absolute;
    margin-top: -350px;
    margin-left: 85px;
    width: 100px;
    height: 220px;
    transform: skew(-30deg);
    background: #f15922;
  }
  .light-para {
    position: absolute;
    width: 100px;
    height: 200px;
    transform: skew(-27deg);
    background: #d5eae3;
    margin-top: -120px;
    margin-left: 30px;
  }
  .margin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .top {
    font-size: 30px;
    font-weight: bold;
    color: #5b595a;
  }
  .tria-1 {
    width: 0;
    height: 0;
    position: absolute;
    margin-left: 540px;
    margin-top: 30px;
    border-top: 20px solid transparent;
    border-left: 50px solid red;
    border-bottom: 30px solid transparent;
  }
  .tria-2 {
    width: 0;
    height: 0;
    position: absolute;
    margin-left: 1150px;
    margin-top: 30px;
    border-top: 20px solid transparent;
    border-right: 50px solid red;
    border-bottom: 30px solid transparent;
  }
  .user {
    font-size: 70px;
    font-weight: bold;
    color: #f15922;
  }
  .txt1 {
    font-size: 28px;
    font-weight: bold;
    color: #5b595a;
  }
  .txt2 {
    font-size: 35px;
    font-weight: bold;
    color: #28327b;
  }
  .txt3-shape {
    position: absolute;
    padding: 10px;
    transform: skew(-20deg);
    background: #d5eae3;
    margin-top: -200px;
    width: 350px;
    font-size: 30px;
    height: 40px;
  }
  .txt3 {
    position: absolute;
    margin-top: -200px;
    font-size: 30px;
    font-weight: bold;
  }
  .txt4 {
    position: absolute;
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    border-bottom: 2px solid #d5eae3;
    color: #5b595a;
    font-size: 30px;
    margin-top: 60px;
    width: 350px;
  }
  .right-triangle {
    width: 0;
    height: 0;
    border-bottom: 350px solid #28327b;
    border-left: 180px solid transparent;
    margin-left: 90%;
  }
  .right-orange {
    position: relative;
    top: -220px;
    left: -0px;
    width: 100px;
    height: 220px;
    right: 150px;
    transform: skew(-30deg);
    background: #f15922;
    margin-left: 83%;
  }
  .right-light {
    position: relative;
    top: -650px;
    left: 13px;
    width: 100px;
    height: 200px;
    right: 90px;
    transform: skew(-27deg);
    background: #d5eae3;
    margin-left: 90%;
  }
}
//download pdf
.download-certification {
  margin: 0 auto;
  width: 100%;
  max-height: 1100px;
  .logos-cert {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin-top: -210px;
    margin-bottom: 80px;
    height: 30%;
    width: 100%;
    .one {
      margin-top: -29px;
      height: 120px;
      width: 300px;
    }
    .two {
      margin-top: -23px;
      height: 140px;
      width: 400px;
    }
    .three {
      height: 90px;
      width: 400px;
    }
    .four {
      margin-left: 30px;
      height: 90px;
      width: 350px;
    }
  }
  .border-line-0 {
    border-top: 2px solid #d5eae3;
    width: 1320px;
    margin-left: 280px;
    padding: 3px;
    margin-top: 45px;
    position: absolute;
    border-right: 2px solid #d5eae3;
    height: 550px;
  }

  .border-line-1 {
    border-top: 5px solid #d5eae3;
    width: 1270px;
    margin-left: 300px;
    padding: 3px;
    margin-top: 70px;
    position: absolute;
    border-right: 5px solid #d5eae3;
    height: 550px;
  }
  .border-line-3 {
    border-bottom: 5px solid #d5eae3;
    width: 1310px;
    position: absolute;
    margin-top: -400px;
    margin-right: 150px;
    border-left: 5px solid #d5eae3;
    height: 550px;
  }
  .border-line-4 {
    border-bottom: 2px solid #d5eae3;
    width: 1320px;
    position: absolute;
    margin-top: -350px;
    margin-right: 200px;
    border-left: 2px solid #d5eae3;
    height: 570px;
  }

  .blue-triangle {
    position: relative;
    width: 0;
    height: 0px;
    border-top: 350px solid #28327b;
    border-right: 180px solid transparent;
  }
  .orange-para {
    position: absolute;
    margin-top: -350px;
    margin-left: 85px;
    width: 100px;
    height: 220px;
    transform: skew(-30deg);
    background: #f15922;
  }
  .light-para {
    position: absolute;
    width: 100px;
    height: 200px;
    transform: skew(-27deg);
    background: #d5eae3;
    margin-top: -120px;
    margin-left: 30px;
  }
  .margin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .top {
    font-size: 30px;
    font-weight: bold;
    color: #5b595a;
  }
  .tria-1 {
    width: 0;
    height: 0;
    position: absolute;
    margin-left: 540px;
    margin-top: 30px;
    border-top: 20px solid transparent;
    border-left: 50px solid red;
    border-bottom: 30px solid transparent;
  }
  .tria-2 {
    width: 0;
    height: 0;
    position: absolute;
    margin-left: 1150px;
    margin-top: 30px;
    border-top: 20px solid transparent;
    border-right: 50px solid red;
    border-bottom: 30px solid transparent;
  }
  .user {
    font-size: 70px;
    font-weight: bold;
    color: #f15922;
  }
  .txt1 {
    font-size: 28px;
    font-weight: bold;
    color: #5b595a;
  }
  .txt2 {
    font-size: 35px;
    font-weight: bold;
    color: #28327b;
  }
  .txt3-shape {
    position: absolute;
    padding: 10px;
    transform: skew(-20deg);
    background: #d5eae3;
    margin-top: -200px;
    width: 350px;
    font-size: 30px;
    height: 40px;
  }
  .txt3 {
    position: absolute;
    margin-top: -200px;
    font-size: 30px;
    font-weight: bold;
  }
  .txt4 {
    position: absolute;
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    border-bottom: 2px solid #d5eae3;
    color: #5b595a;
    font-size: 30px;
    margin-top: 50px;
    width: 350px;
  }
  .right-triangle {
    width: 0;
    height: 0;
    border-bottom: 350px solid #28327b;
    border-left: 180px solid transparent;
    margin-left: 90%;
  }
  .right-orange {
    position: relative;
    top: -220px;
    left: -0px;
    width: 100px;
    height: 220px;
    right: 150px;
    transform: skew(-30deg);
    background: #f15922;
    margin-left: 83%;
  }
  .right-light {
    position: relative;
    top: -650px;
    left: 13px;
    width: 100px;
    height: 200px;
    right: 90px;
    transform: skew(-27deg);
    background: #d5eae3;
    margin-left: 90%;
  }
}
.coach-profiles:after {
  content: '';
  width: 32%;
}

@media screen and (max-width: 480px) {
  .register-bg {
    width: 100%;
    letter-spacing: 0px;
    margin: 0px;
    .account-information {
      width: 100% !important;
      padding: 0 50px;
      box-sizing: border-box;
      .title {
        font-size: 32px;
      }
      .identity {
        .el-input {
          width: 85%;
          margin-left: 5px;
        }
      }
      .sex_group {
        display: flex;
        align-items: center;
        ::v-deep .el-radio-group {
          margin-bottom: -30px;
          .el-radio {
            margin-bottom: 30px;
          }
        }
      }
    }
    .stu-tit {
      font-size: 32px;
    }
    .stu-txt {
      font-size: 28px !important;
    }
    .stu-progress {
      padding: 0 !important;
      margin-bottom: 40px !important;
    }
  }
}
</style>
