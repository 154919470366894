import request from '@/utils/request'

//获取profileList
export function getJobList(page, params) {
    let newurl = `/jobs?page=${page}&count=9`
    for (let item in params) {
        newurl += `&${item}=${params[item]}`
    }
    if (!params || params && !params['user_id']) {
        newurl += `&user_id=`
    }
    return request({
        method: 'GET',
        url: newurl,
    })
}

//获取saveList
export function getSaveList(page, params) {
    let newurl = `/my-savedjobs?page=${page}&count=9`
    for (let item in params) {
        newurl += `&${item}=${params[item]}`
    }
    return request({
        method: 'GET',
        url: newurl,
    })
}
//获取MyList
export function getMyList(page, params) {
    let newurl = `/myjobs?page=${page}&count=9`
    for (let item in params) {
        newurl += `&${item}=${params[item]}`
    }
    return request({
        method: 'GET',
        url: newurl,
    })
}
//获取ApplyList
export function getApplyList(page, params) {
    return request({
        method: 'GET',
        url: `/myapply-jobs?page=${page}&count=9`,
    })
}

//获取LatelyList
export function getLatelyList(id) {
    let newUrl = '/lately-jobs?user_id='
    if (id) {
        newUrl = `/lately-jobs?user_id=${id}`
    }
    return request({
        method: 'GET',
        url: newUrl,
    })
}

//save job
export function saveJob(id) {
    return request({
        method: 'PUT',
        url: `/user-savedJob/${id}`,
    })
}
//unsave job
export function unsaveJob(id) {
    return request({
        method: 'PUT',
        url: `/user-unsavedJob/${id}`,
    })
}
//apply job
export function applyJob(id, params) {
    return request({
        method: 'PUT',
        url: `/job-apply/${id}`,
        params,
    })
}
//unapply job
export function unapplyJob(id) {
    return request({
        method: 'PUT',
        url: `/job-unapply/${id}`,
    })
}

//submitJob
export function submitJob(data) {
    return request({
        method: 'POST',
        url: '/submit-job',
        data,
    })
}

//editJob
export function editJob(id, data) {
    return request({
        method: 'PUT',
        url: `/job/${id}`,
        data,
    })
}
//GetArea
export function GetArea() {
    return request({
        method: 'GET',
        url: `/get-areas`,
    })
}

//GetArea
export function GetJob(id, userId) {
    let newUrl = `/job/${id}?user_id=`
    if (userId) {
        newUrl = `/job/${id}?user_id=${userId}`
  }
    return request({
        method: 'GET',
        url: newUrl,
    })
}

//GetTime
export function GetTime(type) {
    return request({
        method: 'GET',
        url: `/course-report/finish?type=${type}`,
    })
}